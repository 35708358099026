import React from 'react';
import Header from './Header';
import  Stopwatch  from './Stopwatch'

function App() {
  return (
    <>
      <Header title="STOP WATCH" color="blue" />
      <Stopwatch initial={0} />
 

      
    </>
  );
}

export default App;
