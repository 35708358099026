import  React ,{useState , useEffect}from  "react";
export default function Stopwatch(props){
    let  [count , setCount]= useState(props.initial);
    let [mint , setmint] = useState(0);
    let [sec , setsec] = useState(0);

    let  [flag , setflag]  = useState(false);
    let s = true;
    const start = e => {
      setflag(flag = true);
    };

    const reset = e => {
      setflag(flag = false);
      setsec(sec =0)
        setCount(count = 0)
        setmint(mint = 0)


    
    };
      
    

    const stop = e => {
      setflag(flag = false);
    };
    

    
    
      useEffect(_ => {
    
        const intervalToken = setInterval(_ => {
        if (flag){

          
          setCount(count+=1);}
        }, 10);
        if (count >= 100) {setsec(sec +=1)
        setCount(count = 0)}; 
        if(sec>=59){
          setmint(mint+1)
          setsec(sec = 0)
        };
        return _ => {
          clearInterval(intervalToken);
        };
        
        

        
      }); 
      

    return (

        <>
        <div>
    <h1> {mint}m : {sec}s : {count}</h1>
            
           
            <button onClick={start}>start</button>
            <button onClick={stop}>stop</button>
            <button onClick={reset}>reset</button>
            


        </div>
        </>

    );
}